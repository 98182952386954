// frontend/src/App.js - Updated to handle admin-controlled subscriptions
import React from "react";
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from "react-router-dom";
import "./App.css";
import DBStatusPage from "./components/DBStatusPage";
import UploadPage from "./components/UploadPage";
import ChatPage from "./components/ChatPage";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import EmailVerification from "./components/auth/EmailVerification";
import SubscriptionRequired from "./components/subscription/SubscriptionRequired";
import UserManagement from "./components/admin/UserManagement";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import triangleLogo from "./logo.png";

const TriangleIcon = () => (
  <img src={triangleLogo} alt="Logo" width="160" style={{ verticalAlign: "middle" }} />
);

const Logo = () => (
  <img src={triangleLogo} alt="Logo" width="220" style={{ verticalAlign: "middle" }} />
);

// Protected route component
const ProtectedRoute = ({ children, requireAdmin, requireSubscription }) => {
  const { currentUser, hasRole, hasActiveSubscription, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-indicator" style={{ margin: '3rem auto', textAlign: 'center' }}>
        <div className="dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <p>Loading...</p>
      </div>
    );
  }

  // Not logged in, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Admin route check
  if (requireAdmin && !hasRole('admin')) {
    return <Navigate to="/chat" />;
  }

  // Subscription check
  if (requireSubscription && !hasActiveSubscription() && !hasRole('admin')) {
    return <Navigate to="/subscription-required" />;
  }

  return children;
};

// Auth route (redirects to chat if already logged in)
const AuthRoute = ({ children }) => {
  const { currentUser, loading } = useAuth();

  if (loading) {
    return (
      <div className="loading-indicator" style={{ margin: '3rem auto', textAlign: 'center' }}>
        <div className="dots">
          <div className="dot"></div>
          <div className="dot"></div>
          <div className="dot"></div>
        </div>
        <p>Učitavanje ...</p>
      </div>
    );
  }

  if (currentUser) {
    return <Navigate to="/chat" />;
  }

  return children;
};

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}

function AppContent() {
  const { currentUser, logout, hasRole } = useAuth();
  const isAdmin = hasRole('admin');

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="model-selector">
            <Logo />
          </div>

          {currentUser ? (
            <>
              <nav>
                <ul>
                  <li>
                    <Link to="/chat">Chat</Link>
                  </li>
                  {isAdmin && (
                    <>
                      <li>
                        <Link to="/upload">Upload</Link>
                      </li>
                      <li>
                        <Link to="/admin">CMS</Link>
                      </li>
                    </>
                  )}
                </ul>
              </nav>

              <div className="user-menu">
                <button 
                  className="deploy-button" 
                  onClick={logout}
                >
                  Odjava
                </button>
              </div>
            </>
          ) : (
            <>
              <nav>
                <ul>
                  <li>
                    <Link to="/">Početna</Link>
                  </li>
                </ul>
              </nav>
              <div>
                <Link to="/login" className="deploy-button" style={{ marginRight: '10px' }}>
                  Prijava
                </Link>
                <Link to="/register" className="deploy-button">
                  Registracija
                </Link>
              </div>
            </>
          )}
        </header>

        <main className="App-main">
          <Routes>
            {/* Public routes */}
            <Route path="/" element={<HomePage />} />
            <Route
              path="/login"
              element={
                <AuthRoute>
                  <Login />
                </AuthRoute>
              }
            />
            <Route
              path="/register"
              element={
                <AuthRoute>
                  <Register />
                </AuthRoute>
              }
            />
            <Route path="/verify-email" element={<EmailVerification />} />

            {/* Protected routes */}
            <Route
              path="/chat"
              element={
                <ProtectedRoute requireSubscription>
                  <ChatPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/subscription-required"
              element={
                <ProtectedRoute>
                  <SubscriptionRequired />
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute requireAdmin>
                  <UserManagement />
                </ProtectedRoute>
              }
            />

            {/* Legacy routes */}
            <Route path="/db-status" element={<DBStatusPage />} />
            <Route
              path="/upload"
              element={
                <ProtectedRoute requireAdmin>
                  <UploadPage />
                </ProtectedRoute>
              }
            />

            {/* Catch-all redirect */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

function HomePage() {
  const { currentUser } = useAuth();

  return (
    <div className="chat-container">
      <div className="message-container">
        <div className="messages">
          <div className="welcome-logo">
            <Logo />
          </div>
          <div className="welcome-message">
            <h3>Poreznik AI</h3>
            <p>Dobrodošli.</p>
            
            {currentUser ? (
              <Link to="/chat" className="button primary" style={{ display: 'inline-block', maxWidth: '200px', marginTop: '20px' }}>
                Započnite razgovor
              </Link>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <p>Prijavite se ili kreirajte korisnički račun</p>
                <div style={{ marginTop: '20px' }}>
                  <Link to="/login" className="button" style={{ marginRight: '10px' }}>Prijava</Link>
                  <Link to="/register" className="button primary">Registracija</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;