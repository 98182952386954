// src/components/admin/UserManagement.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';

const API_URL = process.env.REACT_APP_API_URL || 'https://worthy-optimism-production.up.railway.app/api';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  
  // Role modal state
  const [selectedUser, setSelectedUser] = useState(null);
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [newRole, setNewRole] = useState('');
  
  // Subscription modal state
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  
  const [actionLoading, setActionLoading] = useState(false);
  
  const { hasRole } = useAuth();
  const isAdmin = hasRole('admin');
  
  useEffect(() => {
    if (!isAdmin) return;
    fetchUsers();
    fetchPlans();
  }, [isAdmin, page]);
  
  const fetchUsers = async () => {
    try {
      setLoading(true);
      setError('');
      
      const response = await axios.get(`${API_URL}/admin/users`, {
        params: {
          page,
          limit: 10,
          search: searchTerm || undefined
        }
      });
      
      if (response.data && response.data.users) {
        setUsers(response.data.users);
        setTotalPages(response.data.pagination?.totalPages || 1);
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Učitavanje korisnika nije uspjelo. Molimo pokušajte ponovo.');
    } finally {
      setLoading(false);
    }
  };
  
  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${API_URL}/admin/plans`);
      
      if (response.data && response.data.plans) {
        setSubscriptionPlans(response.data.plans);
        if (response.data.plans.length > 0) {
          setSelectedPlan(response.data.plans[0].id);
        }
      }
    } catch (err) {
      console.error('Error fetching plans:', err);
    }
  };
  
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1); // Reset to first page
    fetchUsers();
  };
  
  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };
  
  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };
  
  // Role management functions
  const openRoleModal = (user) => {
    setSelectedUser(user);
    setNewRole(user.roles?.includes('admin') ? 'admin' : 'user');
    setShowRoleModal(true);
  };
  
  const closeRoleModal = () => {
    setShowRoleModal(false);
    setSelectedUser(null);
  };
  
  const updateUserRole = async () => {
    if (!selectedUser || !newRole) return;
    
    try {
      setActionLoading(true);
      
      await axios.patch(`${API_URL}/admin/users/${selectedUser.id}/role`, {
        role: newRole
      });
      
      // Update the user in the list
      setUsers(users.map(user => {
        if (user.id === selectedUser.id) {
          return {
            ...user,
            roles: [newRole]
          };
        }
        return user;
      }));
      
      closeRoleModal();
    } catch (err) {
      console.error('Error updating user role:', err);
      setError('Ažuriranje uloge korisnika nije uspjelo. Molimo pokušajte ponovo.');
    } finally {
      setActionLoading(false);
    }
  };
  
  // Subscription management functions
  const openSubscriptionModal = (user) => {
    setSelectedUser(user);
    // Check if user has active subscription
    setSubscriptionStatus(user.subscription && user.subscription.is_active === true);
    setShowSubscriptionModal(true);
  };
  
  const closeSubscriptionModal = () => {
    setShowSubscriptionModal(false);
    setSelectedUser(null);
  };
  
  const toggleUserSubscription = async () => {
    if (!selectedUser) return;
    
    try {
      setActionLoading(true);
      
      const response = await axios.patch(`${API_URL}/admin/users/${selectedUser.id}/subscription`, {
        isSubscribed: subscriptionStatus,
        planId: selectedPlan
      });
      
      if (response.data.success) {
        // Refresh the user list to show updated subscription status
        fetchUsers();
        closeSubscriptionModal();
      } else {
        throw new Error(response.data.error || 'Failed to update subscription');
      }
    } catch (err) {
      console.error('Error updating user subscription:', err);
      setError('Ažuriranje pretplate korisnika nije uspjelo. Molimo pokušajte ponovo.');
    } finally {
      setActionLoading(false);
    }
  };
  
  if (!isAdmin) {
    return (
      <div style={{ textAlign: 'center', padding: '2rem' }}>
        <h2>Zabranjen pristup</h2>
        <p>Nemate dozvolu za pristup ovoj stranici.</p>
      </div>
    );
  }
  
  return (
    <div>
      <div className="admin-header">
        <h2>Upravljanje korisnicima</h2>
        <p>Upravljajte korisnicima i njihovim dozvolama</p>
      </div>
      
      <div className="admin-search">
        <form onSubmit={handleSearch}>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Pretraži po imenu ili e-mailu"
          />
          <button type="submit" className="button">Pretraži</button>
        </form>
      </div>
      
      {error && (
        <div className="error-message">
          {error}
        </div>
      )}
      
      {loading ? (
        <div className="loading-indicator" style={{ margin: '3rem auto', textAlign: 'center' }}>
          <div className="dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <p>Učitavanje korisnika...</p>
        </div>
      ) : (
        <>
          {users.length === 0 ? (
            <div style={{ textAlign: 'center', padding: '2rem', color: 'var(--secondary-text)' }}>
              <p>Nije pronađen nijedan korisnik</p>
            </div>
          ) : (
            <div className="users-table">
              <div className="table-header">
                <div>Korisnik</div>
                <div>E-mail</div>
                <div>Status</div>
                <div>Uloga</div>
                <div>Pretplata</div>
                <div>Akcije</div>
              </div>
              
              {users.map(user => (
                <div key={user.id} className="table-row">
                  <div className="user-info">
                    <div className="user-avatar">
                      {user.full_name ? user.full_name.charAt(0).toUpperCase() : 'K'}
                    </div>
                    <div>
                      <div>{user.full_name || 'Bez imena'}</div>
                      <div style={{ fontSize: '0.8rem', color: 'var(--secondary-text)' }}>
                        Pridružio se: {new Date(user.created_at).toLocaleDateString()}
                      </div>
                    </div>
                  </div>
                  
                  <div>
                    <div>{user.email}</div>
                    <div style={{ fontSize: '0.8rem', marginTop: '0.2rem' }}>
                      <span className={`status-badge ${user.is_email_verified ? 'active' : 'inactive'}`}>
                        {user.is_email_verified ? 'Verificiran' : 'Nije verificiran'}
                      </span>
                    </div>
                  </div>
                  
                  <div>
                    <span className={`status-badge ${user.is_email_verified ? 'active' : 'inactive'}`}>
                      {user.is_email_verified ? 'Aktivan' : 'Neaktivan'}
                    </span>
                  </div>
                  
                  <div>
                    <span className={`role-badge ${user.roles?.includes('admin') ? 'admin' : ''}`}>
                      {user.roles?.includes('admin') ? 'Admin' : 'Korisnik'}
                    </span>
                  </div>
                  
                  <div>
                    <span className={`status-badge ${user.subscription ? 'active' : 'inactive'}`}>
                      {user.subscription ? 'Pretplaćen' : 'Nije pretplaćen'}
                    </span>
                    {user.subscription && (
                      <div style={{ fontSize: '0.8rem', marginTop: '0.2rem', color: 'var(--secondary-text)' }}>
                        Do: {new Date(user.subscription.end_date).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                  
                  <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <button 
                      className="action-button"
                      onClick={() => openRoleModal(user)}
                    >
                      Promijeni ulogu
                    </button>
                    <button 
                      className="action-button"
                      onClick={() => openSubscriptionModal(user)}
                    >
                      {user.subscription ? 'Upravljaj pristupom' : 'Omogući pristup'}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          )}
          
          {totalPages > 1 && (
            <div className="pagination">
              <button 
                className="button"
                onClick={handlePreviousPage}
                disabled={page === 1}
              >
                Prethodna
              </button>
              
              <span className="page-info">
                Stranica {page} od {totalPages}
              </span>
              
              <button 
                className="button"
                onClick={handleNextPage}
                disabled={page === totalPages}
              >
                Sljedeća
              </button>
            </div>
          )}
        </>
      )}
      
      {/* Role Change Modal */}
      {showRoleModal && selectedUser && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h3>Promijeni ulogu korisnika</h3>
              <button className="close-button" onClick={closeRoleModal}>×</button>
            </div>
            
            <div className="modal-content">
              <p>
                Promijeni ulogu za <strong>{selectedUser.full_name || selectedUser.email}</strong>
              </p>
              
              <div style={{ marginTop: '1.5rem' }}>
                <div style={{ marginBottom: '1rem' }}>
                  <label style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <input
                      type="radio"
                      name="role"
                      value="user"
                      checked={newRole === 'user'}
                      onChange={() => setNewRole('user')}
                      style={{ marginRight: '0.5rem' }}
                    />
                    <span>Korisnik</span>
                  </label>
                  <p style={{ marginLeft: '1.5rem', fontSize: '0.9rem', color: 'var(--secondary-text)' }}>
                    Regularni korisnik sa standardnim dozvolama
                  </p>
                </div>
                
                <div>
                  <label style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <input
                      type="radio"
                      name="role"
                      value="admin"
                      checked={newRole === 'admin'}
                      onChange={() => setNewRole('admin')}
                      style={{ marginRight: '0.5rem' }}
                    />
                    <span>Admin</span>
                  </label>
                  <p style={{ marginLeft: '1.5rem', fontSize: '0.9rem', color: 'var(--secondary-text)' }}>
                    Puni pristup svim funkcijama i upravljanju korisnicima
                  </p>
                </div>
              </div>
            </div>
            
            <div className="modal-footer">
              <button 
                className="button"
                onClick={closeRoleModal}
                disabled={actionLoading}
              >
                Otkaži
              </button>
              
              <button 
                className="auth-button"
                onClick={updateUserRole}
                disabled={actionLoading}
                style={{ maxWidth: '120px' }}
              >
                {actionLoading ? 'Spremanje...' : 'Spremi'}
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Subscription Modal */}
      {showSubscriptionModal && selectedUser && (
        <div className="modal-overlay">
          <div className="modal-container">
            <div className="modal-header">
              <h3>{selectedUser.subscription ? 'Upravljaj pristupom chatu' : 'Omogući pristup chatu'}</h3>
              <button className="close-button" onClick={closeSubscriptionModal}>×</button>
            </div>
            
            <div className="modal-content">
              <p>
                {selectedUser.subscription 
                  ? `Upravljaj pristupom AI chatu za ${selectedUser.full_name || selectedUser.email}`
                  : `Omogući pristup AI chatu za ${selectedUser.full_name || selectedUser.email}`
                }
              </p>
              
              <div style={{ marginTop: '1.5rem' }}>
                <label style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                  <input
                    type="checkbox"
                    checked={subscriptionStatus}
                    onChange={() => setSubscriptionStatus(!subscriptionStatus)}
                    style={{ marginRight: '0.5rem' }}
                  />
                  <span>Omogući pristup AI chatu</span>
                </label>
                
                {subscriptionStatus && (
                  <div style={{ marginTop: '1.5rem' }}>
                    <label style={{ display: 'block', marginBottom: '0.5rem' }}>
                      Odaberi plan pretplate:
                    </label>
                    <select 
                      value={selectedPlan} 
                      onChange={(e) => setSelectedPlan(e.target.value)}
                      style={{ 
                        width: '100%', 
                        padding: '0.5rem',
                        backgroundColor: 'var(--bg-color)',
                        color: 'var(--text-color)',
                        border: '1px solid var(--border-color)',
                        borderRadius: '4px'
                      }}
                    >
                      {subscriptionPlans.map(plan => (
                        <option key={plan.id} value={plan.id}>
                          {plan.name.charAt(0).toUpperCase() + plan.name.slice(1)} ({plan.price} KM)
                        </option>
                      ))}
                    </select>
                    <p style={{ marginTop: '0.5rem', fontSize: '0.8rem', color: 'var(--secondary-text)' }}>
                      Ručno omogućene pretplate bit će važeće godinu dana od danas.
                    </p>
                  </div>
                )}
              </div>
            </div>
            
            <div className="modal-footer">
              <button 
                className="button"
                onClick={closeSubscriptionModal}
                disabled={actionLoading}
              >
                Otkaži
              </button>
              
              <button 
                className="auth-button"
                onClick={toggleUserSubscription}
                disabled={actionLoading}
                style={{ maxWidth: '120px' }}
              >
                {actionLoading ? 'Spremanje...' : 'Spremi'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagement;