// frontend/src/components/ChatPage.js
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import TypingEffect from './TypingEffect';
import triangleLogo from '../logo.png'; // Import PNG instead of SVG

// Create a component to use the PNG logo
const TriangleIcon = () => (
  <img src={triangleLogo} alt="Logo" width="120" style={{ verticalAlign: 'middle' }} />
);

const Logo = () => (
  <img src={triangleLogo} alt="Logo" width="220" style={{ verticalAlign: 'middle' }} />
);

const API_URL = process.env.REACT_APP_API_URL || 'https://worthy-optimism-production.up.railway.app/api';

// Message bubble icon for assistant
const MessageBubbleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2Z" fill="white" />
  </svg>
);

const ChatPage = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  // Auto-scroll to bottom of messages
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Generate a new conversation ID each time the component is mounted (page is loaded/refreshed)
  const [conversationId, setConversationId] = useState(`conv_${Date.now()}`);

  // Example queries for quick selection
  const exampleQueries = [
    {
      title: "What are the advantages",
      description: "of using Next.js?"
    },
    {
      title: "Write code to",
      description: "demonstrate dijkstra's algorithm"
    },
    {
      title: "Help me write an essay",
      description: "about silicon valley"
    },
    {
      title: "What is the weather",
      description: "in San Francisco?"
    }
  ];

  // Handle example query click
  const handleExampleClick = (query) => {
    const fullQuery = `${query.title} ${query.description}`;
    setInput(fullQuery);
  };

  // Function to clean the response text by removing the "Reference u zakonima:" section
  const cleanResponseText = (text) => {
    if (!text) return '';
    
    // Find and remove the "Reference u zakonima:" section and everything after it
    const referenceIndex = text.indexOf("\n\nReference u zakonima:");
    if (referenceIndex !== -1) {
      return text.substring(0, referenceIndex);
    }
    
    return text;
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim() || loading) return;

    const userMessage = input.trim();
    setInput('');
    
    // Add user message to chat
    setMessages(prevMessages => [
      ...prevMessages,
      { type: 'user', content: userMessage }
    ]);
    
    setLoading(true);
    
    try {
      // Get previous messages (excluding the one just added)
      const messageHistory = messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }));
      
      // Send query to backend with history
      const response = await axios.post(`${API_URL}/queries`, {
        query: userMessage,
        conversationId,
        messageHistory,
        similarityThreshold: 0.2
      });
      
      // Add assistant response to chat
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          type: 'assistant', 
          content: cleanResponseText(response.data.answer) || 'Nema odgovora',
          sources: response.data.sources || []
        }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prevMessages => [
        ...prevMessages,
        { 
          type: 'assistant', 
          content: cleanResponseText('Došlo je do greške pri komunikaciji s serverom. Molimo pokušajte ponovno.'),
          sources: []
        }
      ]);
    } finally {
      setLoading(false);
    }
  };

  // No need to save messages to localStorage or load them on component mount
  // since we want fresh conversations on each refresh

  const renderSources = (sources) => {
    if (!sources || sources.length === 0) return null;
    
    // Extract unique law names and remove duplicates
    const uniqueLaws = [...new Set(
      sources.map(source => {
        const metadata = source.metadata || {};
        let lawName = metadata.originalName || 'Nepoznat izvor';
        
        // Clean up the law name: remove underscores and .pdf extension
        lawName = lawName.replace(/_/g, ' ').replace(/\.pdf$/i, '');
        
        return lawName;
      })
    )];
    
    return (
      <div className="source-citation">
        {/* <p><strong>Izvor:</strong></p>
        <ul>
          {uniqueLaws.map((lawName, index) => (
            <li key={index}>
              <p>{lawName}</p>
            </li>
          ))}
        </ul> */}
      </div>
    );
  };

  return (
    <div className="chat-container">
      <div className="message-container">
        <div className="messages">
          {messages.length === 0 ? (
            <>
              <div className="welcome-logo">
                <Logo />
              </div>
              <div className="welcome-message">
                <h3>Poreznik AI</h3>
                <p>
                  Razvojna faza u toku.
                </p>
              </div>
              
              {/* <div className="examples-container">
                {exampleQueries.map((query, index) => (
                  <div 
                    key={index} 
                    className="example-card"
                    onClick={() => handleExampleClick(query)}
                  >
                    <h4>{query.title}</h4>
                    <p>{query.description}</p>
                  </div>
                ))}
              </div> */}
            </>
          ) : (
            messages.map((message, index) => (
              <div 
                key={index} 
                className={`message ${message.type === 'user' ? 'user-message' : 'assistant-message'}`}
              >
                <div className="message-header">
                  {message.type === 'assistant' ? (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                      <TriangleIcon />
                      <span style={{ marginLeft: '0.5rem' }}></span>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                      <span>Vaš upit</span>
                    </div>
                  )}
                </div>
                {message.type === 'assistant' ? (
                  <TypingEffect content={message.content} typingSpeed={8} />
                ) : (
                  <ReactMarkdown>{message.content}</ReactMarkdown>
                )}
                {message.sources && renderSources(message.sources)}
              </div>
            ))
          )}
          {loading && (
            <div className="loading-indicator">
              <div className="dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
        
        <form onSubmit={sendMessage} className="input-area">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Pošalji upit ..."
            disabled={loading}
          />
          <button 
            type="submit" 
            disabled={loading || !input.trim()}
          >
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.01 21L23 12L2.01 3L2 10L17 12L2 14L2.01 21Z" fill="#888888" />
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default ChatPage;