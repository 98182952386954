// frontend/src/components/DbStatusPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://worthy-optimism-production.up.railway.app/api';

const DBStatusPage = () => {
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchStatus = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await axios.get(`${API_URL}/documents/diagnostics`);
      setStatus(response.data);
    } catch (err) {
      console.error('Greška pri dobavljanju statusa baze:', err);
      setError(err.message || 'Greška pri dobavljanju statusa baze');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStatus();
  }, []);

  return (
    <div>
      <h2>Status vektorske baze podataka</h2>
      
      <button 
        className="button primary" 
        onClick={fetchStatus}
        disabled={loading}
      >
        {loading ? 'Učitavanje...' : 'Osvježi status'}
      </button>
      
      {error && (
        <div className="status-message error">
          <p>Greška: {error}</p>
        </div>
      )}
      
      {status && (
        <div className="db-status">
          <h3>Informacije o bazi</h3>
          <table className="status-table">
            <tbody>
              <tr>
                <td><strong>Ime indeksa:</strong></td>
                <td>{status.status?.indexName}</td>
              </tr>
              <tr>
                <td><strong>Status indeksa:</strong></td>
                <td>{JSON.stringify(status.status?.indexStatus)}</td>
              </tr>
              <tr>
                <td><strong>Dimenzija:</strong></td>
                <td>{status.status?.dimension}</td>
              </tr>
              <tr>
                <td><strong>Ima vektore:</strong></td>
                <td>{status.status?.hasVectors ? 'Da' : 'Ne'}</td>
              </tr>
              <tr>
                <td><strong>Broj vektora:</strong></td>
                <td>{status.status?.vectorCount}</td>
              </tr>
              <tr>
                <td><strong>Namespace:</strong></td>
                <td>{status.status?.currentNamespace}</td>
              </tr>
              <tr>
                <td><strong>Vrijeme servera:</strong></td>
                <td>{status.serverTime}</td>
              </tr>
            </tbody>
          </table>
          
          <h3>Sirovi podaci</h3>
          <pre className="json-output">
            {JSON.stringify(status, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
};

export default DBStatusPage;