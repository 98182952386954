// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

// Define base API URL - use the same one your app is already using
const API_URL = process.env.REACT_APP_API_URL || 'https://worthy-optimism-production.up.railway.app/api';

// Create context
const AuthContext = createContext();

// Custom hook to use the auth context
export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Check if user is logged in on component mount
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      fetchCurrentUser(token);
    } else {
      setLoading(false);
    }
  }, []);

  // Fetch current user data
  const fetchCurrentUser = async (token) => {
    try {
      setLoading(true);
      
      // Set auth header
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      // Fetch user data
      const response = await axios.get(`${API_URL}/auth/me`);
      
      if (response.data && response.data.user) {
        setCurrentUser(response.data.user);
      }
      
      setError(null);
    } catch (err) {
      console.error('Error fetching user data:', err);
      logout();
      setError('Session expired. Please log in again.');
    } finally {
      setLoading(false);
    }
  };

  // Register new user
  const register = async (email, password, fullName) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await axios.post(`${API_URL}/auth/register`, {
        email,
        password,
        fullName
      });
      
      return {
        success: true,
        message: response.data.message || 'Registration successful. Please check your email to verify your account.'
      };
    } catch (err) {
      console.error('Registration error:', err);
      const errorMessage = err.response?.data?.error || 'Registration failed. Please try again.';
      setError(errorMessage);
      
      return {
        success: false,
        message: errorMessage
      };
    } finally {
      setLoading(false);
    }
  };

  // Login with email and password
  const login = async (email, password) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await axios.post(`${API_URL}/auth/login`, {
        email,
        password
      });
      
      if (response.data && response.data.token) {
        const { token, user } = response.data;
        
        localStorage.setItem('authToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        setCurrentUser(user);
        
        return { success: true };
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Login error:', err);
      const errorMessage = err.response?.data?.error || 'Login failed. Please check your credentials.';
      setError(errorMessage);
      
      return {
        success: false,
        message: errorMessage
      };
    } finally {
      setLoading(false);
    }
  };

  // Login with Google OAuth
  const loginWithGoogle = async (googleData) => {
    try {
      setLoading(true);
      setError(null);
      
      // Extract user info from credential
      const { credential } = googleData;
      const decodedToken = jwtDecode(credential);
      
      // Create a user object from Google response
      const userData = {
        email: decodedToken.email,
        name: decodedToken.name,
        picture: decodedToken.picture,
        sub: decodedToken.sub // Google's user ID
      };
      
      // Send token and user data to backend
      const response = await axios.post(`${API_URL}/auth/google`, {
        token: credential,
        userData
      });
      
      if (response.data && response.data.token) {
        const { token, user } = response.data;
        
        localStorage.setItem('authToken', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        
        setCurrentUser(user);
        
        return { success: true };
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (err) {
      console.error('Google login error:', err);
      const errorMessage = err.response?.data?.error || 'Google login failed. Please try again.';
      setError(errorMessage);
      
      return {
        success: false,
        message: errorMessage
      };
    } finally {
      setLoading(false);
    }
  };

  // Verify email with token
  const verifyEmail = async (token) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await axios.get(`${API_URL}/auth/verify-email?token=${token}`);
      
      return {
        success: true,
        message: response.data.message || 'Email verified successfully.'
      };
    } catch (err) {
      console.error('Email verification error:', err);
      const errorMessage = err.response?.data?.error || 'Email verification failed. Please try again.';
      setError(errorMessage);
      
      return {
        success: false,
        message: errorMessage
      };
    } finally {
      setLoading(false);
    }
  };

  // Logout
  const logout = () => {
    localStorage.removeItem('authToken');
    delete axios.defaults.headers.common['Authorization'];
    setCurrentUser(null);
  };

  // Check if user has a specific role
  const hasRole = (role) => {
    if (!currentUser || !currentUser.roles) return false;
    return currentUser.roles.includes(role);
  };

  // Check if user has an active subscription
  const hasActiveSubscription = () => {
    if (!currentUser || !currentUser.subscription) return false;
    return true;
  };

  // Context value
  const value = {
    currentUser,
    loading,
    error,
    register,
    login,
    loginWithGoogle,
    verifyEmail,
    logout,
    hasRole,
    hasActiveSubscription
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};