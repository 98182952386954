// frontend/src/components/TypingEffect.js
import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

const TypingEffect = ({ content, typingSpeed = 10, markdownEnabled = true }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const [isComplete, setIsComplete] = useState(false);
  const contentRef = useRef(content);
  const indexRef = useRef(0);
  const timerRef = useRef(null);
  
  // Reset typing if content changes
  useEffect(() => {
    // Check if content has changed
    if (content !== contentRef.current) {
      contentRef.current = content;
      indexRef.current = 0;
      setDisplayedContent('');
      setIsComplete(false);
    }
  }, [content]);
  
  // Handle typing effect
  useEffect(() => {
    // Clear any existing timer
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    
    // If typing is complete, don't set a new timer
    if (isComplete) return;
    
    // Function to add the next character
    const typeNextCharacter = () => {
      // If we've typed all characters, mark as complete
      if (indexRef.current >= contentRef.current.length) {
        setIsComplete(true);
        clearInterval(timerRef.current);
        return;
      }
      
      // Add the next character to displayed content
      setDisplayedContent(prevContent => {
        // Make sure we're starting from the right position
        if (prevContent.length !== indexRef.current) {
          console.warn('Typing effect index mismatch. Resetting.');
          indexRef.current = prevContent.length;
        }
        
        // Get the next character
        const nextChar = contentRef.current.charAt(indexRef.current);
        indexRef.current++;
        
        // Return updated content
        return prevContent + nextChar;
      });
    };
    
    // Set interval for typing
    timerRef.current = setInterval(typeNextCharacter, typingSpeed);
    
    // Clean up timer on unmount or when content changes
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [typingSpeed, isComplete]);
  
  // Create placeholder for consistent height
  const placeholderStyle = {
    visibility: 'hidden',
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%'
  };
  
  // Render content
  return (
    <div className="typing-effect-container">
      {/* Invisible placeholder to maintain consistent height */}
      <div style={placeholderStyle}>
        {markdownEnabled ? (
          <ReactMarkdown>{contentRef.current}</ReactMarkdown>
        ) : (
          <div>{contentRef.current}</div>
        )}
      </div>
      
      {/* Visible typing content */}
      <div className="typing-effect">
        {markdownEnabled ? (
          <ReactMarkdown>{displayedContent}</ReactMarkdown>
        ) : (
          <div>{displayedContent}</div>
        )}
      </div>
    </div>
  );
};

export default TypingEffect;