// src/components/subscription/SubscriptionRequired.js
import React from 'react';
import { Link } from 'react-router-dom';

const SubscriptionRequired = () => {
  return (
    <div className="chat-container">
      <div className="message-container">
        <div className="messages">
          <div className="welcome-message" style={{ maxWidth: '500px', margin: '100px auto 0' }}>
            <h3>Potreban pristup</h3>
            <p style={{ marginBottom: '2rem' }}>
              Vaš račun još nema pristup AI chat funkcijama.
            </p>
            
            <div style={{ padding: '1.5rem', backgroundColor: 'var(--input-bg)', borderRadius: '8px', marginBottom: '2rem' }}>
              <h4 style={{ marginBottom: '1rem', fontSize: '1.1rem' }}>Kako dobiti pristup:</h4>
              <ol style={{ paddingLeft: '1.5rem', marginBottom: '1rem' }}>
                <li style={{ marginBottom: '0.5rem' }}>Kontaktirajte administratora da omogući pristup za vaš račun</li>
                <li style={{ marginBottom: '0.5rem' }}>Administrator će aktivirati vašu pretplatu u sistemu</li>
                <li>Nakon što vaš pristup bude odobren, moći ćete koristiti AI chat</li>
              </ol>
              <p style={{ fontSize: '0.9rem', color: 'var(--secondary-text)', marginTop: '1rem' }}>
                Za pitanja o planovima pretplate i cijenama, molimo kontaktirajte svog predstavnika.
              </p>
            </div>
            
            <Link to="/" className="button primary" style={{ display: 'inline-block', marginTop: '1rem' }}>
              Povratak na početnu
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionRequired;