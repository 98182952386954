// frontend/src/components/UploadPage.js
import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faSpinner, faUpload, faCheck, faTimes, faTrash, faSync, faExclamationTriangle, faWrench } from '@fortawesome/free-solid-svg-icons';

const API_URL = process.env.REACT_APP_API_URL || 'https://worthy-optimism-production.up.railway.app/api';

// Triangle logo icon component
const TriangleIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2L20.5 18H3.5L12 2Z" fill="white" />
  </svg>
);

const UploadPage = () => {
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [uploadStatus, setUploadStatus] = useState({});
  const [documents, setDocuments] = useState([]);
  const [documentsLoading, setDocumentsLoading] = useState(false);
  const [documentsError, setDocumentsError] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState({});

  const [showDebugger, setShowDebugger] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    // Filter for PDF files
    const pdfFiles = acceptedFiles.filter(file => file.type === 'application/pdf');
    setFiles(prevFiles => [...prevFiles, ...pdfFiles]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf']
    },
    maxSize: 10 * 1536 * 1536 // 10MB
  });

  // Fetch documents when component mounts
  useEffect(() => {
    fetchDocuments();
  }, []);

  // useEffect(() => {
  //   // Clean up all polling intervals when component unmounts
  //   return () => {
  //     Object.values(pollingIntervals).forEach(intervalId => {
  //       clearInterval(intervalId);
  //     });
  //   };
  // }, [pollingIntervals]);

  const testBackendConnection = async () => {
    try {
      // Test backend connection
      const testUrl = `${API_URL.split('/api')[0]}/api`;
      console.log('Testing backend connection to:', testUrl);
      
      const response = await axios.get(testUrl);
      console.log('Backend connection test:', response);
      
      // Try to get Pinecone status as well
      try {
        const pineconeResponse = await axios.get(`${API_URL.split('/api')[0]}/api/documents/pinecone-status`);
        console.log('Pinecone status:', pineconeResponse.data);
        
        if (pineconeResponse.data.success) {
          alert(`Successfully connected to backend and Pinecone!\nVectors in database: ${pineconeResponse.data.stats.totalVectors || 0}`);
        } else {
          alert(`Successfully connected to backend but Pinecone connection failed: ${pineconeResponse.data.error}`);
        }
      } catch (pineconeError) {
        console.error('Pinecone status check failed:', pineconeError);
        alert('Successfully connected to backend! But Pinecone status check failed.');
      }
    } catch (error) {
      console.error('Backend connection test failed:', error);
      
      let errorMessage = 'Failed to connect to backend';
      if (error.response) {
        errorMessage = `Server responded with error: ${error.response.status} - ${error.response.data.message || error.message}`;
      } else if (error.request) {
        errorMessage = 'No response received from server. Is the backend running?';
      } else {
        errorMessage = `Error: ${error.message}`;
      }
      
      alert(`Backend connection test failed: ${errorMessage}`);
    }
  }

  const fetchDocuments = async () => {
    try {
      setDocumentsLoading(true);
      setDocumentsError(null);
      
      // Fix the API URL to ensure we're calling the correct endpoint
      const apiUrl = `${API_URL.split('/api')[0]}/api/documents/list`;
      console.log("Fetching documents from:", apiUrl);
      
      const response = await axios.get(apiUrl);
      console.log("Document list response:", response.data);
      
      if (response.data.success) {
        setDocuments(response.data.documents || []);
      } else {
        console.error("API returned success:false", response.data);
        setDocumentsError(response.data.error || 'Failed to load documents');
      }
    } catch (error) {
      console.error('Error fetching documents:', error);
      
      let errorMessage = 'Failed to load documents';
      
      if (error.response) {
        // Server responded with an error status
        console.error("Server error response:", error.response.data);
        errorMessage = error.response.data.message || error.response.data.error || `Error ${error.response.status}: ${error.message}`;
      } else if (error.request) {
        // Request was made but no response received
        console.error("No response received:", error.request);
        errorMessage = 'No response from server - check API connection';
      } else {
        // Error in setting up the request
        console.error("Request error:", error.message);
        errorMessage = error.message;
      }
      
      setDocumentsError(errorMessage);
    } finally {
      setDocumentsLoading(false);
    }
  };

  const deleteDocument = async (documentName) => {
    try {
      // Update status to show deletion in progress
      setDeleteStatus(prev => ({
        ...prev,
        [documentName]: 'deleting'
      }));
      
      // Construct the correct URL with the base API URL
      const deleteUrl = `${API_URL.split('/api')[0]}/api/documents/delete/${encodeURIComponent(documentName)}`;
      console.log("Deleting document using URL:", deleteUrl);
      
      const response = await axios.delete(deleteUrl);
      console.log("Delete response:", response.data);
      
      if (response.data.success) {
        // Update status to show successful deletion
        setDeleteStatus(prev => ({
          ...prev,
          [documentName]: 'success'
        }));
        
        // Show result message
        if (response.data.deletedCount) {
          alert(`Successfully deleted document "${documentName}" (${response.data.deletedCount} chunks)`);
        }
        
        // Remove document from list after a delay to show success status
        setTimeout(() => {
          setDocuments(prev => prev.filter(doc => doc.name !== documentName));
          setDeleteStatus(prev => {
            const newStatus = { ...prev };
            delete newStatus[documentName];
            return newStatus;
          });
        }, 1500);
      } else {
        // Update status to show failed deletion
        setDeleteStatus(prev => ({
          ...prev,
          [documentName]: 'error'
        }));
        console.error("Delete failed with response:", response.data);
        alert(`Failed to delete document: ${response.data.message || response.data.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error(`Error deleting document ${documentName}:`, error);
      // Log detailed error information
      if (error.response) {
        console.error("Server error response:", error.response.data);
      }
      
      // Update status to show failed deletion
      setDeleteStatus(prev => ({
        ...prev,
        [documentName]: 'error'
      }));
      
      alert(`Error deleting document: ${error.message}`);
    }
  };

  // Check if a document with the same name already exists
  const checkDocumentExists = (fileName) => {
    return documents.some(doc => doc.name === fileName);
  };

  // Confirm document replacement with the user
  const confirmDocumentReplacement = (fileName) => {
    return window.confirm(`Dokument "${fileName}" već postoji. Da li želite da ga zamijenite?`);
  };
  
  const uploadFile = async (file) => {
    // Check if document with same name already exists
    if (checkDocumentExists(file.name)) {
      // Document exists, ask for confirmation
      const confirmed = confirmDocumentReplacement(file.name);
      
      if (!confirmed) {
        console.log(`Upload of ${file.name} cancelled by user to avoid overwriting existing document`);
        
        setUploadStatus(prev => ({
          ...prev,
          [file.name]: 'cancelled'
        }));
        
        return {
          success: false,
          cancelled: true,
          message: 'Upload cancelled by user'
        };
      }
      
      console.log(`User confirmed replacing document ${file.name}`);
    }
    
    const formData = new FormData();
    formData.append('file', file);
    
    try {
      setUploadStatus(prev => ({
        ...prev,
        [file.name]: 'uploading'
      }));
      
      // Use existing upload endpoint
      const uploadUrl = `${API_URL.split('/api')[0]}/api/documents/upload-sync`;
      console.log("Sending request to: ", uploadUrl);
      
      // Start the upload
      axios.post(uploadUrl, formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prev => ({
            ...prev,
            [file.name]: percentCompleted
          }));
        },
        timeout: 25000, // 25 second timeout
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        console.log(`Upload of ${file.name} completed successfully`);
        setUploadStatus(prev => ({
          ...prev,
          [file.name]: 'success'
        }));
        // Refresh documents after a short delay
        setTimeout(() => fetchDocuments(), 3000);
      })
      .catch(error => {
        console.error(`Upload error or timeout: ${error.message}`);
        
        // If it's a timeout error, show processing message
        if (error.code === 'ECONNABORTED' || error.message.includes('timeout') || 
            error.message.includes('Network Error') || error.message.includes('HTTP2_PROTOCOL_ERROR')) {
          console.log(`The connection timed out, but the file might still be processing...`);
          
          // Set status to "processing"
          setUploadStatus(prev => ({
            ...prev,
            [file.name]: 'processing'
          }));
          
          // Show message to user
          alert(`Dokument "${file.name}" je uspješno poslat i trenutno se obrađuje. Obrada može potrajati oko 15 minuta. Osvježite stranicu kasnije da vidite učitani dokument.`);
        } else {
          // Real error
          setUploadStatus(prev => ({
            ...prev,
            [file.name]: 'error'
          }));
        }
      });
      
      // Return immediately
      return { success: true, message: 'Upload started' };
    } catch (error) {
      console.error('Error starting upload:', error);
      setUploadStatus(prev => ({
        ...prev,
        [file.name]: 'error'
      }));
      return { success: false, error: error.message };
    }
  };

// Add this function to check if a document appears in the list
const checkDocumentExistsInList = (fileName) => {
  // Set status to processing
  setUploadStatus(prev => ({
    ...prev,
    [fileName]: 'processing'
  }));
  
  let attempts = 0;
  const maxAttempts = 30; // 30 minutes
  
  const checkInterval = setInterval(async () => {
    try {
      console.log(`Checking if ${fileName} exists in documents list (attempt ${attempts + 1}/${maxAttempts})...`);
      
      // Call the list documents endpoint
      await fetchDocuments();
      
      // Check if the document exists now
      if (documents.some(doc => doc.name === fileName)) {
        console.log(`Document ${fileName} found in list!`);
        clearInterval(checkInterval);
        
        // Update status
        setUploadStatus(prev => ({
          ...prev,
          [fileName]: 'success'
        }));
        
        // Set progress to 100%
        setUploadProgress(prev => ({
          ...prev,
          [fileName]: 100
        }));

        return;
      } else {
        attempts++;
        
        if (attempts >= maxAttempts) {
          console.log(`Max attempts reached for ${fileName}, stopping checks`);
          clearInterval(checkInterval);
          
          // Final check - if the document still isn't there, mark as error
          if (!documents.some(doc => doc.name === fileName)) {
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
          }
        }
      }
    } catch (error) {
      console.error(`Error checking document list:`, error);
      attempts++;
      
      if (attempts >= maxAttempts) {
        clearInterval(checkInterval);
        setUploadStatus(prev => ({
          ...prev,
          [fileName]: 'error'
        }));
      }
    }
  }, 60000); // Check every 15 seconds
  };
  
  // Add this function to poll for status
  const startStatusPolling = (fileName, uploadId) => {
    let attempts = 0;
    
    const checkStatus = async () => {
      try {
        console.log(`Checking status for ${fileName} (attempt ${attempts + 1})`);
        
        const statusUrl = `${API_URL.split('/api')[0]}/api/documents/upload-status/${uploadId}`;
        const response = await axios.get(statusUrl);
        
        if (response.data.success) {
          const { status, progress } = response.data;
          
          // Update progress (30-100% range for processing)
          if (progress) {
            const adjustedProgress = 30 + (progress * 0.7);
            setUploadProgress(prev => ({
              ...prev,
              [fileName]: Math.round(adjustedProgress)
            }));
          }
          
          if (status === 'completed') {
            console.log(`Processing completed for ${fileName}`);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'success'
            }));
            setUploadProgress(prev => ({
              ...prev,
              [fileName]: 100
            }));
            
            // Refresh document list
            fetchDocuments();
            return; // Stop polling
          } else if (status === 'failed') {
            console.error(`Processing failed for ${fileName}`);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
            return; // Stop polling
          } else {
            // Still processing, continue polling
            attempts++;
            
            // Continue polling up to a reasonable limit (e.g., 2 hours)
            if (attempts < 480) { // 480 * 15 seconds = 2 hours
              setTimeout(checkStatus, 15000); // Check again in 15 seconds
            } else {
              console.error(`Polling timeout for ${fileName}`);
              setUploadStatus(prev => ({
                ...prev,
                [fileName]: 'error'
              }));
            }
          }
        } else {
          console.error(`Status check failed:`, response.data);
          attempts++;
          
          // Retry a few times on failure
          if (attempts < 5) {
            setTimeout(checkStatus, 15000);
          } else {
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
          }
        }
      } catch (error) {
        console.error(`Error checking status:`, error);
        attempts++;
        
        // Retry a few times on error
        if (attempts < 5) {
          setTimeout(checkStatus, 15000);
        } else {
          setUploadStatus(prev => ({
            ...prev,
            [fileName]: 'error'
          }));
        }
      }
    };
    
    // Start the status checking process
    setTimeout(checkStatus, 2000); // First check after 2 seconds
  };

  const pollUploadStatus = async (fileName, uploadId, retries = 30) => {
    let currentRetry = 0;
    
    return new Promise((resolve, reject) => {
      const statusCheckInterval = setInterval(async () => {
        try {
          if (currentRetry >= retries) {
            clearInterval(statusCheckInterval);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
            reject(new Error('Upload status check timed out'));
            return;
          }
          
          currentRetry++;
          
          // Check status endpoint
          const statusUrl = `${API_URL.split('/api')[0]}/api/documents/upload-status/${uploadId}`;
          const statusResponse = await axios.get(statusUrl);
          
          if (!statusResponse.data.success) {
            throw new Error(statusResponse.data.message || 'Failed to check upload status');
          }
          
          const { status, progress, error } = statusResponse.data;
          
          // Update progress (file upload was 0-20%, processing is 20-100%)
          const adjustedProgress = 20 + (progress * 0.8);
          setUploadProgress(prev => ({
            ...prev,
            [fileName]: Math.round(adjustedProgress)
          }));
          
          console.log(`Upload status for ${fileName}: ${status}, progress: ${progress}%`);
          
          // Check status
          if (status === 'completed') {
            clearInterval(statusCheckInterval);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'success'
            }));
            setUploadProgress(prev => ({
              ...prev,
              [fileName]: 100
            }));
            
            // Refresh document list
            fetchDocuments();
            
            resolve({
              success: true,
              message: 'Upload and processing completed successfully'
            });
          } else if (status === 'failed') {
            clearInterval(statusCheckInterval);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
            reject(new Error(error || 'Processing failed'));
          }
          // For 'uploaded' or 'processing' status, continue polling
          
        } catch (error) {
          console.error(`Error checking upload status for ${fileName}:`, error);
          
          // Don't immediately fail, keep trying until max retries
          if (currentRetry >= retries) {
            clearInterval(statusCheckInterval);
            setUploadStatus(prev => ({
              ...prev,
              [fileName]: 'error'
            }));
            reject(error);
          }
        }
      }, 3000); // Check every 3 seconds
    });
  };

  const uploadAll = async () => {
    // Check for duplicates first and show alert if needed
    const existingFiles = files.filter(file => checkDocumentExists(file.name));
    
    if (existingFiles.length > 0) {
      const fileNames = existingFiles.map(file => file.name).join(', ');
      const confirmMsg = existingFiles.length === 1
        ? `Dokument "${fileNames}" već postoji u bazi. Da li želite da ga zamijenite?`
        : `Sljedeći dokumenti već postoje u bazi: ${fileNames}. Da li želite da ih zamijenite?`;
      
      if (!window.confirm(confirmMsg)) {
        console.log('Batch upload cancelled due to existing documents');
        
        // Mark cancelled files
        existingFiles.forEach(file => {
          setUploadStatus(prev => ({
            ...prev,
            [file.name]: 'cancelled'
          }));
        });
        
        // Upload only non-existing files
        for (const file of files) {
          if (!checkDocumentExists(file.name) && !uploadStatus[file.name]) {
            await uploadFile(file);
          }
        }
        
        return;
      }
    }
    
    // Upload files one by one to not overload the server
    for (const file of files) {
      if (!uploadStatus[file.name]) {
        await uploadFile(file);
      }
    }
  };

  const removeFile = (fileName) => {
    setFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
    setUploadProgress(prev => {
      const newProgress = { ...prev };
      delete newProgress[fileName];
      return newProgress;
    });
    setUploadStatus(prev => {
      const newStatus = { ...prev };
      delete newStatus[fileName];
      return newStatus;
    });
  };

  const getStatusIcon = (fileName) => {
    const status = uploadStatus[fileName];
    
    if (status === 'uploading') {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    } else if (status === 'success') {
      return <FontAwesomeIcon icon={faCheck} style={{ color: '#4ade80' }} />;
    } else if (status === 'error') {
      return <FontAwesomeIcon icon={faTimes} style={{ color: '#ef4444' }} />;
    } else if (status === 'cancelled') {
      return <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#f59e0b' }} />;
    }
    
    // Check for potential conflicts with existing documents
    const exists = checkDocumentExists(fileName);
    
    return exists ? 
      <FontAwesomeIcon icon={faExclamationTriangle} style={{ color: '#f59e0b' }} title="Dokument već postoji" /> : 
      <FontAwesomeIcon icon={faUpload} />;
  };

  const getDeleteStatusIcon = (documentName) => {
    const status = deleteStatus[documentName];
    
    if (status === 'deleting') {
      return <FontAwesomeIcon icon={faSpinner} spin />;
    } else if (status === 'success') {
      return <FontAwesomeIcon icon={faCheck} style={{ color: '#4ade80' }} />;
    } else if (status === 'error') {
      return <FontAwesomeIcon icon={faTimes} style={{ color: '#ef4444' }} />;
    }
    
    return <FontAwesomeIcon icon={faTrash} />;
  };

  // Format date to be more readable
  const formatDate = (dateString) => {
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
    } catch (e) {
      return dateString || 'Unknown date';
    }
  };

  const [pollingIntervals, setPollingIntervals] = useState({});

  return (
    <div className="chat-container">
      <div className="message-container">
        <div className="messages" style={{ justifyContent: 'flex-start' }}>
          <div className="welcome-message">
            <h3>Učitaj dokumente</h3>
            <p>Učitajte PDF dokumente za analizu i pretragu</p>
          </div>
          
          <div className="upload-container">
            <div className="upload-section" style={{ marginBottom: '2rem' }}>
              <button 
                className="button" 
                onClick={testBackendConnection}
                style={{ marginBottom: '20px', marginLeft: 'auto', display: 'block' }}
              >
                Testiraj konekciju s backendom
              </button>
              
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'active' : ''}`}
              >
                <input {...getInputProps()} />
                <p>Prevucite i spustite PDF fajlove ovdje, ili kliknite da ih odaberete</p>
                <p className="dropzone-info" style={{ color: '#888', fontSize: '0.8rem', marginTop: '0.5rem' }}>
                  Maksimalna veličina fajla: 10MB
                </p>
              </div>
              
              {files.length > 0 && (
                <div className="file-list">
                  <h3 style={{ color: '#fff', fontWeight: '500', marginBottom: '1rem', fontSize: '1rem' }}>
                    Odabrani fajlovi
                  </h3>
                  {files.map((file) => {
                    const fileExists = checkDocumentExists(file.name);
                    return (
                      <div key={file.name} className="file-item">
                        <div className="file-icon">
                          <FontAwesomeIcon icon={faFilePdf} />
                        </div>
                        <div className="file-name">
                          {file.name}
                          {fileExists && uploadStatus[file.name] !== 'uploading' && uploadStatus[file.name] !== 'success' && (
                            <div style={{ fontSize: '0.8rem', color: '#f59e0b', marginTop: '0.25rem' }}>
                              Ovaj dokument već postoji u bazi
                            </div>
                          )}
                          {uploadStatus[file.name] === 'cancelled' && (
                            <div style={{ fontSize: '0.8rem', color: '#f59e0b', marginTop: '0.25rem' }}>
                              Učitavanje otkazano
                            </div>
                          )}
                        </div>
                        <div className="file-status">
                          {getStatusIcon(file.name)}
                        </div>
                        <button 
                          className="button"
                          onClick={() => removeFile(file.name)}
                          disabled={uploadStatus[file.name] === 'uploading'}
                          style={{ padding: '0.4rem 0.75rem', marginLeft: '0.5rem' }}
                        >
                          Ukloni
                        </button>
                        {uploadProgress[file.name] !== undefined && uploadProgress[file.name] < 100 && (
                          <div className="upload-progress" style={{ marginTop: '0.5rem', height: '3px', width: '100%', backgroundColor: '#333', borderRadius: '3px', overflow: 'hidden' }}>
                            <div 
                              className="progress-bar"
                              style={{ width: `${uploadProgress[file.name]}%`, height: '100%', backgroundColor: '#0070f3' }}
                            ></div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  
                  <button 
                    className="button primary"
                    onClick={uploadAll}
                    disabled={files.length === 0 || Object.values(uploadStatus).includes('uploading')}
                    style={{ marginTop: '1rem' }}
                  >
                    Učitaj sve
                  </button>
                </div>
              )}
              
              {Object.entries(uploadStatus).some(([_, status]) => status === 'success') && (
                <div className="status-message success" style={{ marginTop: '1rem', padding: '1rem', borderRadius: '4px', backgroundColor: 'rgba(74, 222, 128, 0.1)', color: '#4ade80' }}>
                  <p>Uspješno učitani dokumenti! Sada možete razgovarati s njima.</p>
                </div>
              )}
              
              {Object.entries(uploadStatus).some(([_, status]) => status === 'error') && (
                <div className="status-message error" style={{ marginTop: '1rem', padding: '1rem', borderRadius: '4px', backgroundColor: 'rgba(239, 68, 68, 0.1)', color: '#ef4444' }}>
                  <p>Neki fajlovi nisu uspješno učitani. Molimo pokušajte ponovo.</p>
                </div>
              )}
            </div>
            
            {/* Document Management Section */}
            <div className="document-management" style={{ marginTop: '2rem', borderTop: '1px solid #333', paddingTop: '1.5rem' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                <h3 style={{ color: '#fff', fontWeight: '500', fontSize: '1.2rem' }}>
                  Upravljanje dokumentima
                </h3>
                <button 
                  className="button"
                  onClick={fetchDocuments}
                  disabled={documentsLoading}
                  style={{ padding: '0.4rem 0.75rem' }}
                >
                  {documentsLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <><FontAwesomeIcon icon={faSync} /> Osvježi</>
                  )}
                </button>
              </div>
              
              {documentsError && (
                <div className="status-message error" style={{ padding: '1rem', borderRadius: '4px', backgroundColor: 'rgba(239, 68, 68, 0.1)', color: '#ef4444', marginBottom: '1rem' }}>
                  <p><FontAwesomeIcon icon={faExclamationTriangle} /> {documentsError}</p>
                </div>
              )}
              
              {documentsLoading ? (
                <div style={{ textAlign: 'center', padding: '2rem' }}>
                  <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                  <p style={{ marginTop: '1rem', color: '#888' }}>Učitavanje dokumenata...</p>
                </div>
              ) : documents.length === 0 ? (
                <div style={{ textAlign: 'center', padding: '2rem', backgroundColor: 'rgba(255, 255, 255, 0.05)', borderRadius: '4px' }}>
                  <p style={{ color: '#888' }}>Nema učitanih dokumenata</p>
                  <p style={{ fontSize: '0.9rem', color: '#666', marginTop: '0.5rem' }}>
                    Učitajte PDF dokumente koristeći obrazac iznad
                  </p>
                </div>
              ) : (
                <div className="document-list">
                  <div className="document-header" style={{ 
                    display: 'grid', 
                    gridTemplateColumns: '1fr 120px 100px', 
                    padding: '0.75rem', 
                    borderBottom: '1px solid #333',
                    fontWeight: 'bold',
                    color: '#aaa'
                  }}>
                    <div>Dokument</div>
                    <div>Datum učitavanja</div>
                    <div>Akcije</div>
                  </div>
                  
                  {documents.map((doc) => (
                    <div key={doc.name} className="document-item" style={{ 
                      display: 'grid', 
                      gridTemplateColumns: '1fr 120px 100px', 
                      padding: '0.75rem',
                      borderBottom: '1px solid #222',
                      alignItems: 'center'
                    }}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: '0.75rem', color: '#ff6b6b' }} />
                        <div>
                          <div style={{ fontWeight: '500' }}>{doc.name}</div>
                          <div style={{ fontSize: '0.8rem', color: '#888', marginTop: '0.25rem' }}>
                            {doc.chunks} {doc.chunks === 1 ? 'chunk' : 'chunks'}
                          </div>
                        </div>
                      </div>
                      
                      <div style={{ fontSize: '0.85rem', color: '#aaa' }}>
                        {formatDate(doc.uploadDate)}
                      </div>
                      
                      <div>
                        <button 
                          className="button"
                          onClick={() => deleteDocument(doc.name)}
                          disabled={deleteStatus[doc.name] === 'deleting'}
                          style={{ 
                            padding: '0.4rem 0.75rem',
                            backgroundColor: deleteStatus[doc.name] === 'error' ? '#8B0000' : 
                                            deleteStatus[doc.name] === 'success' ? '#2E8B57' : 
                                            'transparent'
                          }}
                          title="Izbriši dokument"
                        >
                          {getDeleteStatusIcon(doc.name)}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPage;