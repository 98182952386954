// src/components/auth/Register.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Register = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  
  const { register, loginWithGoogle } = useAuth();
  const navigate = useNavigate();
  
  // Modified useEffect hook for Login.js and Register.js
// Add this to both components to ensure Google button renders reliably

useEffect(() => {
  let scriptLoadTimer;
  let buttonRenderTimer;
  
  // Load Google's OAuth script
  const loadGoogleScript = () => {
    // Remove any existing Google script
    const existingScript = document.getElementById('google-oauth');
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.id = 'google-oauth';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      initializeGoogleAuth();
    };
    
    // Additional fallback if script loads but button doesn't render
    scriptLoadTimer = setTimeout(() => {
      if (window.google && document.getElementById('google-login-button')) {
        initializeGoogleAuth();
      }
    }, 1000);
  };

  // Initialize Google Auth
  const initializeGoogleAuth = () => {
    if (!window.google) {
      console.log("Google API not available yet");
      return;
    }
    
    try {
      // Ensure the button container is empty before rendering
      const buttonContainer = document.getElementById('google-login-button');
      if (buttonContainer) {
        buttonContainer.innerHTML = '';
        
        window.google.accounts.id.initialize({
          client_id: "275590093071-l0rai91sk94ledtpb4h8btjs6ck6gh6l.apps.googleusercontent.com",
          callback: handleGoogleCallback,
          auto_select: false,
          cancel_on_tap_outside: true,
        });

        // Render the button
        window.google.accounts.id.renderButton(
          buttonContainer,
          { 
            type: 'standard', 
            theme: 'outline', 
            size: 'large',
            text: 'signin_with',
            shape: 'rectangular',
            logo_alignment: 'left',
            width: 280
          }
        );
        
        // If button still doesn't appear, show fallback
        buttonRenderTimer = setTimeout(() => {
          if (buttonContainer.children.length === 0) {
            document.getElementById('google-login-fallback').style.display = 'block';
          }
        }, 2000);
      }
    } catch (error) {
      console.error("Error initializing Google Auth:", error);
      // Show fallback button if the official one fails
      document.getElementById('google-login-fallback').style.display = 'block';
    }
  };

  loadGoogleScript();
  
  // Check again after a delay to ensure button is rendered
  const renderCheckTimer = setTimeout(() => {
    const buttonContainer = document.getElementById('google-login-button');
    if (buttonContainer && buttonContainer.children.length === 0 && window.google) {
      initializeGoogleAuth();
    }
  }, 1500);
  
  // Clean up timers on component unmount
  return () => {
    clearTimeout(scriptLoadTimer);
    clearTimeout(buttonRenderTimer);
    clearTimeout(renderCheckTimer);
  };
}, []);

  // Handle Google OAuth response
  const handleGoogleCallback = async (response) => {
    setIsLoading(true);
    setErrorMessage('');
    setSuccessMessage('');
    
    try {
      const credential = response.credential;
      
      // Send the ID token to your backend
      const result = await loginWithGoogle({ credential });
      
      if (result.success) {
        navigate('/chat');
      } else {
        setErrorMessage(result.message);
      }
    } catch (error) {
      console.error('Google sign up error:', error);
      setErrorMessage('Google sign up failed. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    setSuccessMessage('');
    setIsLoading(true);
    
    // Validation
    if (!fullName || !email || !password || !confirmPassword) {
      setErrorMessage('Please fill in all fields');
      setIsLoading(false);
      return;
    }
    
    if (password !== confirmPassword) {
      setErrorMessage('Passwords do not match');
      setIsLoading(false);
      return;
    }
    
    if (password.length < 6) {
      setErrorMessage('Password must be at least 6 characters long');
      setIsLoading(false);
      return;
    }
    
    const result = await register(email, password, fullName);
    
    if (result.success) {
      setSuccessMessage(result.message);
      // Optionally redirect after delay
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    } else {
      setErrorMessage(result.message);
    }
    
    setIsLoading(false);
  };
  
  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h2>Kreirajte račun</h2>
          <p>Kreirajte račun</p>
        </div>
        
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        
        {successMessage && (
          <div className="success-message">
            {successMessage}
          </div>
        )}
        
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="fullName">Ime i prezime</label>
            <input
              type="text"
              id="fullName"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder="Unesite Vaše ime i prezime"
              disabled={isLoading}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Unesite Vaš email"
              disabled={isLoading}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Lozinka</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Unesite Vašu lozinku"
              disabled={isLoading}
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="confirmPassword">Potvrdite lozinku</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Potvrdite Vašu lozinku"
              disabled={isLoading}
              required
            />
          </div>
          
          <button 
            type="submit" 
            className="auth-button"
            disabled={isLoading}
          >
            {isLoading ? 'Kreiranje računa ...' : 'Registracija'}
          </button>
        </form>
        
        <div className="auth-divider">
          <span>ILI</span>
        </div>
        
        <div className="social-auth">
          {/* Google Sign-up Button Container */}
          <div id="google-signup-button" className="google-button-container"></div>
          
          {/* Fallback Google Button for when script fails to load */}
          <div id="google-signup-fallback" style={{ display: 'none' }}>
            <button 
              className="social-button"
              onClick={() => {
                window.open('https://accounts.google.com/o/oauth2/v2/auth?client_id=' +
                  process.env.REACT_APP_GOOGLE_CLIENT_ID +
                  '&redirect_uri=' + window.location.origin + '/auth/google/callback' +
                  '&response_type=code&scope=email%20profile&access_type=offline',
                  '_self'
                );
              }}
            >
              <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
              </svg>
              Registrirajte sa Google
            </button>
          </div>
        </div>
        
        <div className="auth-footer">
          <p>
            Imate korisnički račun? <Link to="/login">Prijavite se</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;