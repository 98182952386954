// src/components/auth/EmailVerification.js
import React, { useEffect, useState } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const EmailVerification = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isVerifying, setIsVerifying] = useState(true);
  const [verificationStatus, setVerificationStatus] = useState('');
  const [error, setError] = useState('');
  
  const { verifyEmail } = useAuth();
  
  useEffect(() => {
    const verifyToken = async () => {
      if (!token) {
        setIsVerifying(false);
        setError('Token za verifikaciju nedostaje');
        return;
      }
      
      const result = await verifyEmail(token);
      
      setIsVerifying(false);
      
      if (result.success) {
        setVerificationStatus(result.message);
      } else {
        setError(result.message);
      }
    };
    
    verifyToken();
  }, [token, verifyEmail]);
  
  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h2>Verifikacija e-maila</h2>
        </div>
        
        <div className="verification-content">
          {isVerifying ? (
            <div className="loading-message">
              <p>Verifikacija vaše e-mail adrese...</p>
              <div className="loading-indicator">
                <div className="dots">
                  <div className="dot"></div>
                  <div className="dot"></div>
                  <div className="dot"></div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {error ? (
                <>
                  <div className="error-message">{error}</div>
                  <p>Molimo zatražite novi link za verifikaciju ili kontaktirajte podršku.</p>
                </>
              ) : (
                <>
                  <div className="success-message">{verificationStatus}</div>
                  <p>Sada se možete prijaviti na svoj račun.</p>
                </>
              )}
            </div>
          )}
          
          <div style={{ textAlign: 'center', marginTop: '2rem' }}>
            <Link to="/login" className="auth-button" style={{ display: 'inline-block', maxWidth: '200px' }}>
              Idi na prijavu
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;